<!-- 持续发展 -->
<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/cooperation/develop/en/绿色制造.png" class="images"
          v-show="bottomBorderIndex == 1">
        <img src="@/assets/cooperation/develop/en/永续经营.png" class="images"
          v-show="bottomBorderIndex == 2">
        <img src="@/assets/cooperation/develop/en/员工关怀.png" class="images"
          v-show="bottomBorderIndex == 3">
        <img src="@/assets/cooperation/develop/en/新技术研发.png" class="images"
          v-show="bottomBorderIndex == 4">
      </el-carousel-item>
    </el-carousel>
    <!-- 中间内容区域 -->
    <div class="content">
      <!-- 头部标题 -->
      <div class="title">
        <ul>
          <li v-for="item in titleList" :key="item.id" @click="titleBtn(item.id)"
            :class="{ bottomBorder: bottomBorderIndex == item.id }">{{ item.name }}</li>
        </ul>
      </div>
      <!-- 文字图片 -->
      <div v-for="item in titleUrl" :key="item.id">
        <img :src="item.url" v-if="item.id == bottomBorderIndex" class="titleUrl">
      </div>
      <!-- 中间内容 -->
      <p v-for="item in tableList" :key="item.id">{{ item.title }}</p>
      <!-- 下载 -->
      <img :src="reportUrl" class="report" @click="downloadPdf">
    </div>
    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/cooperation/develop/zh/绿色制造.png" class="images"
          v-show="bottomBorderIndex == 1">
        <img src="@/assets/cooperation/develop/zh/永续经营.png" class="images"
          v-show="bottomBorderIndex == 2">
        <img src="@/assets/cooperation/develop/zh/员工关怀.png" class="images"
          v-show="bottomBorderIndex == 3">
        <img src="@/assets/cooperation/develop/zh/新技术研发.png" class="images"
          v-show="bottomBorderIndex == 4">
      </van-swipe-item>
    </van-swipe>
    <!-- 内容区域 -->
    <div class="content">
          <!-- 中间内容头部区域 -->
          <div class="title">
            <ul>
              <li v-for="item in titleListMove" :key="item.id" @click="titleBtn(item.id)"
                :class="{ bottomBorder: bottomBorderIndex == item.id }">{{ item.name }}
              </li>
            </ul>
            <!-- 中间内容文字图片区域 -->
            <div v-for="item in titleUrl" :key="item.id">
             <img :src="item.url" v-if="item.id == bottomBorderIndex" class="titleUrl">
          </div>
          <!-- 文字内容 -->
          <p v-for="item in tableList" :key="item.id">{{ item.title }}</p>
          <!-- 底部图片区域 -->
          <img :src="reportUrl" class="report titleUrl" @click="downloadPdf">
      </div>
    </div>
    <!-- 底部 -->
    <move_foot style="margin-top: -2.3rem;"></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRoute } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import {downloadPdf} from '@/utils/downloadExportFile.js'

export default {
  components: { foot, headers, move_header, move_foot },

  setup() {
    const route = useRoute();
    const state = reactive({
      bottomBorderIndex: 1,
      // 文字内容展示的数据
      tableList: [],
      titleList: [
        { id: 4, name: "Common development" },
        { id: 3, name: "Employee Care" },
        { id: 2, name: "Sustainable Operation" },
        { id: 1, name: "Green Manufacturing" },
      ],
      titleListMove: [
        { id: 1, name: "Green Manufacturing" },
        { id: 2, name: "Sustainable Operation" },
        { id: 3, name: "Employee Care" },
        { id: 4, name: "Common development" },
      ],
      reportUrl: require('@/assets/cooperation/develop/en/报告下载端.png'),
      titleUrl: [
        { id: 1, url: require('@/assets/cooperation/develop/en/绿色制造-text.png') },
        { id: 2, url: require('@/assets/cooperation/develop/en/永续经营-text.png') },
        { id: 3, url: require('@/assets/cooperation/develop/en/员工关怀-text.png') },
        { id: 4, url: require('@/assets/cooperation/develop/en/新技术发展-text.png') },
      ],
      // 绿色制造文字内容
      greenWord: [
        { id: 1, title: "We adhere to the vision of 'bigger, farther, be an everlasting company' as the initial heart.In conjunction with the stakeholders,we build the company's sustainable development strategy system from the three aspects of society, environment and governance, establish a scientific and rational decision-making governance structure, set sustainable development goals, and actively respond to the Carbon Peaking and Carbon Neutrality Goals proposed by the Chinese government. We will execute the sustainable development strategy of the company through the directions of low-carbonization of energy consumption, recycling of resource utilization, cleaner production, greening of product supply and supply logistics, and digitalization of production." },
        { id: 2, title: "Strengthening the management system and promoting the improvement of governance capacity. A compliant and legal management system is extremely important for the sustainable development of the company. The construction of green manufacturing system, safe production, environmental protection and green smart manufacturing are the way for companies to realize green and low-carbon development in the future. In this regard, we contribute to green and sustainable development by constantly revising, adjusting and adding documents and systems and making them legally compliant." },
        { id: 3, title: "In 2022, we passed the recognition of the relevant system. Meanwhile, in order to obtain the certification of ISO27001 information security management system, we have increased the construction of information security, so as to further gain the trust of our customers and the market, and to enhance the reputation." },
        { id: 4, title: "At present, climate change mitigation has become a global consensus. In response to the Carbon Peaking and Carbon Neutrality Goals, we are actively taking various measures, such as R&D and use of equipment and new materials, production process improvement, setting energy consumption targets, adoption of electronic workflows, etc., to reduce carbon emissions and improve the ecological environment. It realizes positive and friendly development and win-win situation between the company and the environment, as well as green and high-quality sustainable development of the company. In 2022, our electricity consumption reaches 15,201,322 kWh. We will set and promote energy efficiency and carbon reduction targets to achieve a renewable energy utilization rate of 5% in 2023." },
        { id: 5, title: "At the same time, we have established a rigorous water resource management system and team. In 2022, we saved a total of 18,000 tons of water, a 12.5% decrease from 2021. It will continue to improve the reuse of water resources such as surface water and rainwater, and set a target to reduce water consumption by 10%." },
        { id: 6, title: "For waste, we strictly follow the relevant national and local laws and regulations. In accordance with the principles of waste minimization, waste recycling and environmentally sound management, as well as the principles of unified recycling, classified management and standardized handling, a distinction is made between non-hazardous and hazardous wastes generated in the course of production and operation. For non-hazardous waste, we reuse and recycle." },
        { id: 7, title: "    For waste gas, we adopt centralized control and treatment. Emissions are harmonized according to relevant standards and monitored in real time. The data are up to standard as we have passed the testing and supervision of testing organizations with government-issued qualifications." },
      ],
      // 经营文字内容
      manageWord: [
        { id: 1, title: "Shine Optics Technology Company Limited,  headquartered in Shuangqiao Economic Development Zone, Chongqing City, China, is a high-tech company specializing in the design, research and development, manufacturing and sales of high-precision camera modules. In the future, the company will make steady progress in accordance with the strategy of sustainable development and global layout. Existing in Chongqing City, Fenyi County, Jiangxi Province, Delhi, India, three places have intelligent manufacturing base. There are also several production bases and service organizations such as Chongqing Shinetech Algorithm Software Co. Ltd, Shenzhen New Technology Research Center, Singapore Company, and Overseas R&D Centers." },
        { id: 2, title: "Our application areas focus on high-end camera products for cell phones, tablet computers, laptops, digital cameras, automotive imaging system, autonomous driving, smart wearable device, medical, smart home, smart city, security installations, drones, aerospace and other fields. We serve and fulfill the needs of our customers and users through strong technical support. The future development strategy of our company is to continuously extend into the field of hardware manufacturing and software service of imaging technology, and endeavor to develop into a company whose growth is driven by technological innovation." },
        { id: 3, title: "In the era of intelligent interconnection of everything and human-computer interaction, we have stepped up our R&D and innovation efforts to help businesses, individuals and devices realize the possibility of building a smart world with comprehensive visual awareness technologies. Through the rich range of smart products, we meet the needs of different customers and enable them to share the convenience brought by the era of smart connectivity. In the future, in addition to our existing business areas, we will also gradually move towards the AIoTaaS model to meet the wide range of needs of users in different industries through new solutions for smart cameras." },
      ],
      // 员工关怀
      empWord: [
        { id: 1, title: "As a legally compliant company, we know that the development of us can not be separated from the employees' efforts, the rights and interests of employees can not be separated from our people-oriented concept. We have established a series of systems and documents to protect the legitimate rights and interests of our employees in accordance with laws and regulations, industry standards and our own situation." },
        { id: 2, title: "We always adhere to the production safety policy of safety first and precaution crucial, integrated management and people-oriented. A safe and healthy work environment is what we strive to provide for our employees, as we advocate the concept of safety in production, take the health home. There are sound occupational health and safety management systems in place. We make long-term public announcements of our Occupational Health Management Measures and conduct annual training on production safety knowledge, so that everyone can build up an awareness of  three defense lines of self-insurance and mutual protection in the production process." },
        { id: 3, title: "Meanwhile, we are well aware that talents are the core driving force of science and technology innovation. Talent as the company's first strategic resources, we incubate talents through external recruitment and internal training, promotion, training and other forms. With a complete career development channel and talent development system, we provide two-way development choice for employees to realize their own value and the company grows together." },
        { id: 4, title: " In 2022, we launched the training and certification of internal lecturers to build a comprehensive team, providing a powerful channel to spread corporate culture and pass on work experience and knowledge. As the guide of employees' career development, we make a good effort to 'transmit, aid and lead', and cultivate and provide the right people for our business." },
        { id: 5, title: "'Healthy work, happy life' has always been what we advocate. Every employee is Shinetech's family. We organizes colorful activities such as company open day, parent-child garden activity, birthday party for employees, etc., to care for the physical and mental health of them and enrich their daily life, so that everyone's life and work to achieve a balance." },
      ],
      // 研发与发展
      RDWord: [
        { id: 1, title: "The development and application of green technology is an essential direction for the company's future development towards low-carbon recycling. We will invest in the use of green technology products such as outdoor energy storage and photovoltaic power generation, and will continue to strengthen the investment in green technology. At the same time, we enhance the reuse of part of the energy through process improvement." },
        { id: 2, title: "At the level of science and technology innovation, through continuous efforts, we have a total of 452 authorized patents, 86 computer software copyrights, 109 invention patents and 250 utility model patents. We have received a lot of honors in Jiangxi, such as Top 20 Cultural Enterprise, Demonstration Enterprise of Informatization and Industrialization Integration, Specialized and Sophisticated Enterprise, Top 50 Excellent and Strong Enterprise, Jiangxi Enterprise Technology Center and so on. We have also won the honors in Chongqing, as Science and Technology Enterprise, Technology Innovation Center, SME Technology R&D Center, Double Hundred Enterprise, Top 100 Manufacturing Enterprise, Digitalized Workshop, Chongqing Enterprise Technology Center, lntegration of lnformationization and industrialization Management System Certificate, Dazu Top 20 Industry, Emerging Industry, Intellectual Property Advantageous Enterprise, Intelligent Terminal Enterprises, and so on." },
        { id: 3, title: "Supply chain management is one of the important aspects of company operation. A green supply chain is to drive the upstream and downstream enterprises to continuously improve the utilization of resources and energy while we do a proper job of energy saving, emission reduction and environmental protection, so as to achieve the purpose of improving environmental performance and realizing the green and sustainable development of the whole supply chain." },
        { id: 4, title: "We strictly comply with the Responsible Business Alliance guidelines, actively introduce the supplier ESG evaluation process, and push forward the construction of procurement accountability management system. When selecting suppliers, we comprehensively examine and improve suppliers' social responsibility performance in four dimensions: labor standards, health and safety, environmental impact, and business ethics." },
        { id: 5, title: "  As a company with a strong sense of social responsibility, we know that development cannot be separated from social support. Upholding corporate social responsibility and giving back to society, we are positively involved in public welfare undertakings such as subsidizing students to study, and environmental protection." },
      ]
    });
    let methods = {
      // 点击标题list
      titleBtn(id) {
        state.bottomBorderIndex = id
        switch (id) {
          case 1:
            state.tableList = state.greenWord
            break;
          case 2:
            state.tableList = state.manageWord
            break;
          case 3:
            state.tableList = state.empWord
            break;
          case 4:
            state.tableList = state.RDWord
            break;

          default:
            break;
        }
      },
            // 点击下载中文报告
        downloadPdf() {
          downloadPdf('https://www.shine-optics.com/video/ows/sustainable_development.pdf','sustainable_development')
    }
    }
    onMounted(() => {
      window.scrollTo(0, 0)
      state.tableList = state.greenWord
    });
    return {
      ...toRefs(state),
      ...methods
    };
  },
};
</script>

<style scoped lang="less">
.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

.box {

  width: 100%;

  .bottomBorder {
    border-bottom: 3px solid red;
  }

  .content {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    width: 70%;
    height: auto;

    p {
      padding: 0 30px;
      text-indent: 2em;
      Letter-spacing: 1px;
      font-size: 1.25rem;
    }

    .title {
      width: 100%;
      height: 50px;
      margin-top: 20px;

      li {
        float: right;
        cursor: pointer;
        display: inline-block;
        margin-right: 10px;
        font-size: 25px;
      }
    }

    .report {
      width: 100%;
      cursor: pointer;
      margin-top: 30px;
    }

    .titleUrl {
      width: 100%;

    }
  }
}


.images {
  width: 100%;
  height: auto;
  max-height: 600px;
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;
    p {
      padding: 0 1em;
      text-indent: 3.5em;
      Letter-spacing: 1px;
      font-size: 3.75rem;
      text-align: left;
    }
    .bottomBorder{
      border-bottom: 10px solid red;
    }
    .content {
      position: relative;
      padding: 30px;
      .titleUrl{
        width: 100%;
        margin-top: 30px;
      }
      .report{
        cursor: pointer;
      }
      .title {
      width: 100%;
      margin: 0 0 35px 0;
        text-align: center;
      li {
        cursor: pointer;
        display: inline-block;
        margin-right: 20px;
        font-size: 3.75rem;
        
      }
    }

    }
  }
}</style>